import React from 'react'
import "./aboutus.scss"
const Aboutus = () => {
    return (
        <>
            <section id='aboutus' className='mainabout'>
                <div className="custom-container">
                    <div className='maincontent'>
                        <h6>ABOUT US</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="2" viewBox="0 0 97 2" fill="none">
                            <path d="M1 1H96" stroke="#008CF7" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        <h2 className='whitehead'>Who <span>We Are</span> </h2>
                        <h5 className='holdpara'>Hodlr Technology is a leading Web3 development company specializing in secure blockchain wallet solutions. We bring decades of combined experience in blockchain technology.</h5>
                        <h6 className='whiteendpara'><span>Mission Statement:</span> Building secure, user-friendly Web3 wallets for a decentralized future.</h6>
                        <div className='formobil d-none'>
                            <img src='\assets\hhhhhh.svg' alt='img' className='innerimg'/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Aboutus
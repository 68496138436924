import React from 'react'
import "./getintouch.scss"
const Getintouch = () => {
    return (
        <>
            <section className='mainget' id='getintouch'>
                <div className="custom-container">
                    <div className='parent'>
                        <div className='left'>
                            <div className='mainheading'>
                                <h6>CONTACT</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" width="104" height="2" viewBox="0 0 104 2" fill="none">
                                    <path d="M1 1H103" stroke="#008CF7" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <h2>Get In Touch</h2>
                                <p>We'd love to hear from you! Reach out to us via email or phone for any inquiries or support.</p>
                            </div>
                        </div>
                        <div className='right'>
                            <div className='parentbox'>
                                <div className='box'>
                                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M19.8334 4.08337H8.16671C4.66671 4.08337 2.33337 5.83337 2.33337 9.91671V18.0834C2.33337 22.1667 4.66671 23.9167 8.16671 23.9167H19.8334C23.3334 23.9167 25.6667 22.1667 25.6667 18.0834V9.91671C25.6667 5.83337 23.3334 4.08337 19.8334 4.08337ZM20.3817 11.1884L16.73 14.105C15.96 14.7234 14.98 15.0267 14 15.0267C13.02 15.0267 12.0284 14.7234 11.27 14.105L7.61837 11.1884C7.24504 10.885 7.18671 10.325 7.47837 9.95171C7.78171 9.57837 8.33004 9.50837 8.70337 9.81171L12.355 12.7284C13.2417 13.44 14.7467 13.44 15.6334 12.7284L19.285 9.81171C19.6584 9.50837 20.2184 9.56671 20.51 9.95171C20.8134 10.325 20.755 10.885 20.3817 11.1884Z" fill="#008CF7" />
                                    </svg> info@hodlrtech.com</p>
                                </div>
                                <div className='box1'>
                                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M12.8916 17.4417L10.7333 19.6C10.2783 20.055 9.55492 20.055 9.08825 19.6117C8.95992 19.4834 8.83158 19.3667 8.70325 19.2384C7.50158 18.025 6.41658 16.7534 5.44825 15.4234C4.49159 14.0934 3.72159 12.7634 3.16159 11.445C2.61325 10.115 2.33325 8.84337 2.33325 7.63004C2.33325 6.83671 2.47325 6.07837 2.75325 5.37837C3.03325 4.66671 3.47659 4.01337 4.09492 3.43004C4.84158 2.69504 5.65825 2.33337 6.52158 2.33337C6.84825 2.33337 7.17492 2.40337 7.46658 2.54337C7.76992 2.68337 8.03825 2.89337 8.24825 3.19671L10.9549 7.01171C11.1649 7.30337 11.3166 7.57171 11.4216 7.82837C11.5266 8.07337 11.5849 8.31837 11.5849 8.54004C11.5849 8.82004 11.5033 9.10004 11.3399 9.36837C11.1883 9.63671 10.9666 9.91671 10.6866 10.1967L9.79992 11.1184C9.67158 11.2467 9.61325 11.3984 9.61325 11.585C9.61325 11.6784 9.62492 11.76 9.64825 11.8534C9.68325 11.9467 9.71825 12.0167 9.74158 12.0867C9.95158 12.4717 10.3133 12.9734 10.8266 13.58C11.3516 14.1867 11.9116 14.805 12.5183 15.4234C12.6349 15.54 12.7633 15.6567 12.8799 15.7734C13.3466 16.2284 13.3583 16.975 12.8916 17.4417Z" fill="#008CF7" />
                                        <path d="M25.6316 21.385C25.6316 21.7117 25.5733 22.05 25.4566 22.3767C25.4216 22.47 25.3866 22.5633 25.3399 22.6567C25.1416 23.0767 24.8849 23.4733 24.5466 23.8467C23.9749 24.4767 23.3449 24.9317 22.6333 25.2233C22.6216 25.2233 22.61 25.235 22.5983 25.235C21.91 25.515 21.1633 25.6667 20.3583 25.6667C19.1683 25.6667 17.8966 25.3867 16.5549 24.815C15.2133 24.2433 13.8716 23.4733 12.5416 22.505C12.0866 22.1667 11.6316 21.8283 11.2 21.4667L15.015 17.6517C15.3416 17.8967 15.6333 18.0833 15.8783 18.2117C15.9366 18.235 16.0066 18.27 16.0883 18.305C16.1816 18.34 16.275 18.3517 16.38 18.3517C16.5783 18.3517 16.7299 18.2817 16.8583 18.1533L17.7449 17.2783C18.0366 16.9867 18.3166 16.765 18.5849 16.625C18.8533 16.4617 19.1216 16.38 19.4133 16.38C19.6349 16.38 19.8683 16.4267 20.1249 16.5317C20.3816 16.6367 20.65 16.7883 20.9416 16.9867L24.8033 19.7283C25.1066 19.9383 25.3166 20.1833 25.4449 20.475C25.5616 20.7667 25.6316 21.0583 25.6316 21.385Z" fill="#008CF7" />
                                    </svg>+123 456 7890</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Getintouch
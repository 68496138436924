import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Newterms from './components/Terms/Newterms';
import Newprivacy from './components/Terms/Newprivacy';


function App() {

  return (
    <>
      <Router>
        <Route
          exact
          path='/'
          render={() => (<Landing />)}
        />
             <Route
          exact
          path='/terms'
          render={() => (<Newterms />)}
        />
           <Route
          exact
          path='/privacy'
          render={() => (<Newprivacy />)}
        />
      </Router>
      
    </>
  );
}

export default App;

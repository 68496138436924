import React from 'react'
import "./ourwork.scss"
const Ourwork = () => {
    return (
        <>
            <section className='ourwork' id='ourwork'>
                <div className="custom-container">
                    <div className='parentcontent'>
                        <div className='left'>
                            <div className='mainheading'>
                                <h6>Our Work</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" width="104" height="2" viewBox="0 0 104 2" fill="none">
                                    <path d="M1 1H103" stroke="#008CF7" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <div className='maincrypto'>
                                    <img src='\assets\crypto.svg' alt='img' className='img-fluid cryptoimg' />
                                  
                                </div>
                                <h2 className='cryptohead'>Crypto wallet</h2>
                                <p>Developed a multi-chain wallet</p>
                            </div>
                        </div>
                        <div className='left'>
                            <div className='mainworkimg'>
                                <img src='\assets\mobileimg.svg' alt='img' className='img-fluid innerimg'/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Ourwork
import React from 'react'
import './footer.scss'
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;

    const location = useLocation();
  const getClassName = (path) => {
    return location.pathname === path ? 'active-class' : 'inactive-class'; // Set class name based on route
  };
    return (
        <>
            <section className="mainfooter">
                <div className="custom-container">
                    <div className="innerfooter">
                        <div className="footerleft">
                            <img src="\logo.svg" alt="logo" className="logo" />
                         
                        </div>
                        <div className="footerright">
                            <div className="footerlinks">
                                <h6 className="footerlinkhead FORMNNN">Nav</h6>
                                <div className="innerfooterlinks">
                                    <HashLink class="footerlinkpara" to="/#home" style={
                                        isActive("#home")
                                            ? {
                                                color: "#008CF7"
                                            }
                                            : {}
                                    }>Home</HashLink>
                                    <HashLink class="footerlinkpara" to="/#aboutus" style={
                                        isActive("#aboutus")
                                            ? {
                                                color: "#008CF7"
                                            }
                                            : {}
                                    }>About Us</HashLink>
                                    <HashLink class="footerlinkpara" to="/#choose" style={
                                        isActive("#choose")
                                            ? {
                                                color: "#008CF7"
                                            }
                                            : {}
                                    }>Our Expertise</HashLink>
                                    <HashLink class="footerlinkpara" to="/#ourwork" style={
                                        isActive("#ourwork")
                                            ? {
                                                color: "#008CF7"
                                            }
                                            : {}
                                    }>Our Work</HashLink>
                                      <Link to="/privacy" className={getClassName('/privacy')}>Privacy Policy</Link>
          
            <Link to="/terms" className={getClassName('/terms')}>Terms & Conditions</Link>
                                </div>
                            </div>
                            <div className="footerlinks">
                                <div className='mblbrdr d-none'></div>
                                    <h6 className="footerlinkhead">Join us</h6>
                                    <div className="innerfooterlinks m-0">
                                    <a href="#" target='_blank' className="footerlinkpara">
                                        <img src="\assets\twitter.svg" alt="socialimg" className="socialimg" />
                                        Twitter</a>
                                    <a href="#" target='_blank' className="footerlinkpara">
                                        <img src="\assets\Telegram.svg" alt="socialimg" className="socialimg" />
                                        Telegram</a>
                                    <a href="#" target='_blank' className="footerlinkpara">
                                        <img src="\assets\LinkedIn.svg" alt="socialimg" className="socialimg" />
                                        LinkedIn</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
             
            
                </div>
                <div className='brdr'></div>
                <div className='custom-container'>
                <div className="lastline">
                 
                 <p className="lastlineparaleft">
                     Copyright © 2024 <span className="bold">Hodlr Technology</span> All Rights Reserved.
                 </p>
                 <HashLink className="toppara" to="#home">
                     Go to Top
                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                         <path d="M10.5408 6.52009C10.43 6.52009 10.3192 6.47925 10.2317 6.39175L7.00001 3.16009L3.76834 6.39175C3.59917 6.56092 3.31917 6.56092 3.15001 6.39175C2.98084 6.22259 2.98084 5.94259 3.15001 5.77342L6.69084 2.23259C6.86001 2.06342 7.14001 2.06342 7.30917 2.23259L10.85 5.77342C11.0192 5.94259 11.0192 6.22259 10.85 6.39175C10.7683 6.47925 10.6517 6.52009 10.5408 6.52009Z" fill="#008CF7" />
                         <path d="M7 12.8959C6.76083 12.8959 6.5625 12.6975 6.5625 12.4584V2.64087C6.5625 2.4017 6.76083 2.20337 7 2.20337C7.23917 2.20337 7.4375 2.4017 7.4375 2.64087V12.4584C7.4375 12.6975 7.23917 12.8959 7 12.8959Z" fill="#008CF7" />
                     </svg>
                 </HashLink>
             </div>
             </div>
            </section>
            {/* <div className="custom-container">
                <div className="lastline">
                    <p className="lastlineparaleft">
                        Copyright © 2024 <span className="bold">Hodlr Technologies LTD.</span> All Rights Reserved.
                    </p>
                    <HashLink className="toppara" to="#home">
                        Go to Top
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <path d="M10.5408 6.52009C10.43 6.52009 10.3192 6.47925 10.2317 6.39175L7.00001 3.16009L3.76834 6.39175C3.59917 6.56092 3.31917 6.56092 3.15001 6.39175C2.98084 6.22259 2.98084 5.94259 3.15001 5.77342L6.69084 2.23259C6.86001 2.06342 7.14001 2.06342 7.30917 2.23259L10.85 5.77342C11.0192 5.94259 11.0192 6.22259 10.85 6.39175C10.7683 6.47925 10.6517 6.52009 10.5408 6.52009Z" fill="#008CF7" />
                            <path d="M7 12.8959C6.76083 12.8959 6.5625 12.6975 6.5625 12.4584V2.64087C6.5625 2.4017 6.76083 2.20337 7 2.20337C7.23917 2.20337 7.4375 2.4017 7.4375 2.64087V12.4584C7.4375 12.6975 7.23917 12.8959 7 12.8959Z" fill="#008CF7" />
                        </svg>
                    </HashLink>
                </div>
            </div> */}
        </>
    )
}

export default Footer
import React from 'react'
import './banner.scss'
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import Header from '../Header/Header';

const Banner = () => {
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <>
      <section id='home' className="mainbanner">
      <Header />
        <video className="main-banner-video"
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="https://res.cloudinary.com/drt6vurtt/video/upload/v1726068889/hodlr/bannervideo_zmgoww.mp4" type="video/mp4" />
        </video>
        <span className="topshade"></span>
        <div className="custom-container">
          <div className="innerbanner">
            <h4 className="mainbannerhead">
            Leading the 
            </h4>
            <h3 className='boldhead'>Web3 Revolution</h3>
            <p className="mainbannerpara">
            Secure and scalable blockchain wallet solutions
            </p>
            <div className="bannerbtns">
              {/* <HashLink className="greenbtn" to="#contact">Contact Us</HashLink> */}
              <HashLink class="nav-link" to="/#getintouch" className="bluebtn">
              Get Started
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
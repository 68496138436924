import '../../App.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Specialities from './Specialities/Specialities.js';
import Choose from './Choose/Choose.js';
import Contactus from './Contactus/Contactus.js';
import Footer from './Footer/Footer.js';
import Aboutus from '../Aboutus/Aboutus.js';
import Ourwork from '../Ourwork/Ourwork.js';
import Getintouch from '../Getintouch/Getintouch.js';

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
 
      <Banner />
      <Aboutus />
      {/* <Specialities /> */}
      <Choose />
      <Ourwork />
      <Getintouch />
       {/* <Contactus />  */}
      <Footer />
    </>
  );
}

export default Landing;